// extracted by mini-css-extract-plugin
export var vote_bar = "_vote-module--vote_bar--aq8jj";
export var disp = "_vote-module--disp---RjCK";
export var vote_bar_ng = "_vote-module--vote_bar_ng--C5NwF";
export var vote = "_vote-module--vote--tS8yT";
export var vote__wrap = "_vote-module--vote__wrap--OY5DX";
export var vote__inner = "_vote-module--vote__inner--sLXJM";
export var vote__img = "_vote-module--vote__img--uFSBp";
export var vote__sp_style = "_vote-module--vote__sp_style--kjTcm";
export var vote__box = "_vote-module--vote__box--T-WY3";
export var vote__date = "_vote-module--vote__date--vcyeH";
export var vote__game = "_vote-module--vote__game--q7OvM";
export var vote__category = "_vote-module--vote__category--pJ75a";
export var vote__theme = "_vote-module--vote__theme--H6HLV";
export var vote__blue = "_vote-module--vote__blue--0w-ij";
export var vote__red = "_vote-module--vote__red--24OYh";
export var vote__match = "_vote-module--vote__match--g-eaR";
export var vote__match_data = "_vote-module--vote__match_data--TFTPg";
export var vote__match_set = "_vote-module--vote__match_set--BrB0S";
export var vote__artist_name = "_vote-module--vote__artist_name--xPUhV";
export var vote__artist_box_blue = "_vote-module--vote__artist_box_blue--AgxNQ";
export var vote__artist_box_red = "_vote-module--vote__artist_box_red--5S5jw";
export var vote__artist = "_vote-module--vote__artist--A+plK";
export var vote__blue_btn = "_vote-module--vote__blue_btn--qkZmz";
export var vote__red_btn = "_vote-module--vote__red_btn--RpcQL";
export var vote__vs = "_vote-module--vote__vs--GxNP7";
export var vote__reload_btn = "_vote-module--vote__reload_btn--ZOEZh";
export var vote__annotation = "_vote-module--vote__annotation--r1Msl";
export var vote__back_btn = "_vote-module--vote__back_btn--iCmkC";
export var vote__bg_bottom = "_vote-module--vote__bg_bottom--9pJny";